.rt-tr-group {
  background-color: white;
}
.rt-tr-group:nth-of-type(even) {
  background-color: #f5f6fa;
}
.rt-tr-group:hover {
  background-color: #d4dde9;
}

.sticky-header-dash {
  position: sticky;
  top: 0;
  z-index: 1;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 5;
}

.disable {
  pointer-events: none;
  opacity: 0.4;
}

.trans {
  transition: width 2s;
}

.primary-color-icon {
  color: #042e60;
}

.stripe-button {
  display: block;
  position: relative;
  padding: 0 12px;
  height: 30px;
  background: #1275ff;
  background-image: -webkit-linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
  background-image: -moz-linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
  background-image: -ms-linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
  background-image: linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
  font-size: 15px;
  line-height: 30px;
  color: white;
  font-weight: bold;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 5px;
}

#mapBox div:first-child {
  position: relative !important;
}

@media only screen and (max-width: 768px) {
  .direction {
    flex-direction: row !important;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }

  .show-step-count {
    display: flex;
  }

  .hide-top-bar {
    display: none;
  }

  .position-col-for-mobile {
    flex-direction: column;
  }

  .w-full-mobile {
    width: 100%;
    margin-bottom: 10px;
  }

  .margin-top-mob {
    margin-top: 10px;
  }

  .custom-margin {
    margin-right: 0 !important;
  }

  .AppWrapper {
    min-width: 350px;
  }
}

@media only screen and (min-width: 768px) {
  .show-step-count {
    display: none;
  }

  .AppWrapper {
    width: 700;
  }
}

@media only screen and (min-width: 900px) {
  .width-40 {
    width: 40%;
  }
}

@media only screen and (max-width: 900px) {
  .width-80 {
    width: 80%;
  }
}

.direction {
  flex-direction: column;
  display: flex !important;
}

.custom-margin {
  margin-right: 15rem;
}

.radius {
  border-radius: 1rem;
  border-width: 0.1px;
}

.train-size {
  width: 200px;
}

.triangle-topleft {
  width: 0;
  height: 0;
  border-top: 20px solid #6b4d4d;
  border-right: 20px solid transparent;
}

.caption-text {
  font-size: 11px;
  color: white;
  opacity: 0.5;
}

.faEdit:hover {
  color: rgb(58, 150, 255);
}

#mapBox div:first-child {
  position: relative !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overscroll-behavior-x: none;
}

input[type='radio'] + label span {
  transition: background 0.2s, transform 0.2s;
}

input[type='radio'] + label span:hover,
input[type='radio'] + label:hover span {
  transform: scale(1.2);
}

input[type='radio']:checked + label span {
  background-color: #3490dc; //bg-blue
  box-shadow: 0px 0px 0px 2px white inset;
}

input[type='radio']:checked + label {
  color: #3490dc; //text-blue
}

.gradient {
  background-image: linear-gradient(135deg, #4c62a0 35%, #90a3c2 100%);
}

.tog-text {
  color: black;
  position: absolute;
  top: 20%;
  right: 0;
  font-size: 10px;
}

.tog-text-training {
  color: black;
  position: absolute;
  top: 25%;
  right: 0;
  font-size: 10px;
}

.toggle__dot {
  top: +0.25rem;
  left: +0.25rem;
  flex-direction: row;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #48bb78 !important;
}

input:checked ~ .tog-text {
  left: 0;
}

input:checked ~ .tog-text-training {
  left: -25px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dropdown-toggle::after {
  color: white;
}

.dropdown-toggle {
  display: flex !important;
  align-items: center;
}

#blackSelect {
  .css-1uccc91-singleValue {
    color: white !important;
  }
}

.Toastify__toast--success {
  color: #042e60 !important;
  border-radius: 8px !important;
  font-size: medium;
  background: #ffffff !important;
  border: 4px solid #2ecf28 !important;
  text-align: center;
}

.Toastify__toast--warning {
  color: #042e60 !important;
  border-radius: 8px !important;
  font-size: medium;
  background: #ffffff !important;
  border: 4px solid #cf2831 !important;
  text-align: center;
}

#custom-dimensions {
  .DatePicker_dateDayItem__3UYPG {
    height: 53px !important;
    width: 50px !important  ;
    color: #042e60 !important;
  }
  .DatePicker_button__2M4aC {
    outline: none !important;
  }
  .DatePicker_button__2M4aC:hover {
    background-color: #526d8d !important;
  }
}

@media only screen and (max-width: 900px) {
  .mini-hide {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .desktop-view {
    display: none !important;
  }

  .trainings-margin {
    margin-top: 5px;
  }

  .trainings-width {
    width: 100%;
  }

  .mini-view {
    display: flex;
    flex-direction: column;
  }
}

.day-highlight {
  color: #042e60 !important;
}

.minimum-height {
  min-height: 450px;
}

.navbar-toggler {
  background-color: #ebedef !important;
  outline: none !important;
  margin-left: 15px;
}

.fa {
  padding: 10px;
  font-size: 17px;
  width: 40px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  color: #042e60;
  margin-left: 2px;
}

/* Add a hover effect if you want */
.fa:hover {
  opacity: 0.7;
}

/* Facebook */
.fa-facebook {
  color: #042e60 !important;
  width: 15px;
  padding: 0px !important;
  text-align: left;
  margin-left: 0px;
  margin-right: 3px;
}

/* Twitter */
.fa-twitter {
  color: #042e60 !important;
  width: 13px;
  padding: 0px !important;
  text-align: left;
  margin-left: 0px;
  margin-right: 10px;
}

.fa-instagram {
  color: #042e60 !important;
  width: 13px;
  padding: 0px !important;
  text-align: left;
  margin-left: 0px;
  margin-right: 10px;
}

.fa-globe {
  color: #042e60 !important;
  width: 13px;
  padding: 0px !important;
  text-align: left;
  margin-left: 0px;
  margin-right: 10px;
}

.fa-tiktok {
  color: #042e60 !important;
  width: 13px;
  padding: 0px !important;
  text-align: left;
  margin-left: 0px;
  margin-right: 7px;
}

#rsd__select-month {
  height: 42px !important;
  width: 100% !important;
  padding-left: 5px !important;
  margin-right: 10px !important;
  outline: none !important;
  border-radius: 3px;
  border-color: #d7dae2 !important;
  border-width: 1px !important;
  color: #818181 !important;
}

#rsd__select-day {
  height: 42px !important;
  width: 100% !important;
  padding-left: 5px !important;
  margin-right: 10px !important;
  outline: none !important;
  border-radius: 3px;
  border-color: #d7dae2 !important;
  border-width: 1px !important;
  color: #818181 !important;
}

#rsd__select-year {
  height: 42px !important;
  width: 100% !important;
  padding-left: 5px !important;
  margin-right: 10px !important;
  outline: none !important;
  border-radius: 3px;
  border-color: #d7dae2 !important;
  border-width: 1px !important;
  color: #818181 !important;
}

.rsd__select-container-day,
.rsd__select-container-month,
.rsd__select-container-year {
  label {
    display: none;
  }
}

#rsd__select-day:focus,
#rsd__select-month:focus,
#rsd__select-year:focus {
  border-color: #042e60 !important;
  border-width: 2px !important;
}

.cutom-border {
  border-color: #98999b !important;
  border-width: 1px;
  border-right-width: 0;
  border-left-width: 0;
}

.nav-link.active {
  background-color: transparent !important;
  border-left-width: 0;
  font-family: SF-semibold;
  // font-weight: bold !important;
}

#custom-group {
  margin-bottom: 4px !important;
}

.flatpickr-calendar {
  box-shadow: none !important;
  font-size: 11px !important;
  width: 298px !important;
  margin-top: 30px;
}

.flatpickr-current-month {
  font-size: 12px !important;
  font-weight: bold !important;
  font-family: 'SF-Regular';
}

span.flatpickr-day {
  border: 0px !important;
  width: 10px;
}

.fc-event-main,
.un-booked-event {
  border-color: #008cdd !important;
  background-color: white !important;
  border-width: 1px;
  padding: 0px !important;
}

.fc-daygrid-day-frame {
  height: 300px;
}

.fc-daygrid-more-link {
  font-weight: normal !important;
  visibility: hidden !important;
}
.fc-daygrid-more-link::after {
  visibility: visible;
  content: 'View all' !important;
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
  font-size: 13px !important;
  color: #042e60 !important;
  font-family: 'sf-regular';
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end {
}

table {
  border-collapse: collapse;
}

.gfg {
  border-collapse: separate;
  border-spacing: 0 10px;
}

.max-location-height {
  max-height: 65px;
}

.line-connector {
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-color: #d3d3d3;
}

.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
  background-color: transparent !important;
  border-color: #d7dae2 !important;
}
.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
  background-color: transparent !important;
  border-color: #d7dae2 !important;
}

.fc .fc-button-primary {
  color: #4d4f5c !important;
  text-transform: capitalize !important;
}

.fc .fc-toolbar {
  padding-right: 100px !important;
}

.css-13bclu2-Switch {
  top: 1px !important;
  left: 3px !important;
}

.css-12ehpnt-Switch {
  left: 18px !important;
}

.css-yk16xz-control {
  border: 1px solid #d7dae2 !important;
  border-radius: 4px !important;
  height: calc(1.5em + 0.75rem + 12px) !important;
}

.css-k8zvk-control {
  border: 1px solid #d7dae2 !important;
  border-radius: 4px !important;
  height: calc(1.5em + 0.75rem + 12px) !important;
}

.css-1pahdxg-control .input-mask {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}

.css-1unu37t {
  inset: 2px 2px 2px 19px !important;
}

.css-1pahdxg-control {
  box-shadow: none !important;
  border-color: #234672 !important;
  border-width: 2px !important;
}

.css-1k45dkh-control {
  box-shadow: none !important;
  outline: none !important;
  border-color: #234672 !important;
  border-width: 2px !important;
}

.css-1uccc91-singleValue {
  color: #042e60 !important;
  opacity: 0.7 !important;
}

#label-margin {
  margin-bottom: -2px !important;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#schedule-card {
  .card {
    border: none !important;
    border-color: transparent !important;
    margin-bottom: 0.1rem;
  }
}

.circle-dimensions {
  height: 6px;
  width: 6px;
}

.fc-col-header-cell {
  background-color: #f5f6fa !important;
  color: #a3a6b4 !important;
  font-size: 8px !important;
  padding: 5px !important;
  text-transform: uppercase !important;
}

.rsd__select-container-month {
  width: 31% !important;
  margin-right: 3% !important;
}

.rsd__day-container {
  width: 31% !important;
  margin-right: 3% !important;
}

.rsd__year-container {
  width: 31% !important;
}

.react-date-picker__wrapper {
  border: none !important;
}

.pulse {
  background-color: #c21818;
  cursor: pointer;
  animation: pulse 2s infinite;
}
.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 44, 44, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 44, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 44, 44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 44, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 44, 44, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 44, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 44, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 44, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 44, 44, 0);
  }
}

.landing-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.backdrop {
  background-color:rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(1px);
}

.landing-transition {
  transition: all 0.2s ease-in-out;
}

.collapsible {
  overflow: hidden;

  &.collapsed {
    height: 0;

  }

  &.expanded {
    height: 600px;
  }
}
