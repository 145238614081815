// * {
//   box-sizing: border-box;
// }

.sign-up-datepicker,
.custom-datepicker {
  gap: .4rem;

  .rsd__select-container-year,
  .rsd__select-container-day,
  .rsd__select-container-month {
    max-width: 32%;

    label {
      color: #000;
      font-size: 12.75px;
      font-weight: 500;
      margin-bottom: 0;
    }

    select {
      border: 1px solid #d8dbe0;
      border-radius: 0.25rem;
      padding: 0.375rem 0.75rem;
      font-size: 0.875rem;
      color: #768192;
    }
  }
}
