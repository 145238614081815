.font-montserrat-Regular {
  font-family: Montserrat-Regular;
}

.font-montserrat-bold {
  font-family: Montserrat-Bold;
}
.font-montserrat-semibold {
  font-family: Montserrat-SemiBold;
}

.font-montserrat-medium {
  font-family: Montserrat-Medium;
}

.font-sf-bold {
  font-family: SF-bold;
}

.font-sf-semibold {
  font-family: SF-semibold;
}

.font-sf-light {
  font-family: SF-light;
}

.font-sf-regular {
  font-family: SF-regular;
}

.font-sf-medium {
  font-family: SF-medium;
}

@font-face {
  font-family: 'SF-bold';
  font-style: normal;
  font-weight: normal;
  src: local('SF-Pro-Display-Bold'), url('../fonts/SF-Pro-Display-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'SF-semibold';
  font-style: normal;
  font-weight: normal;
  src: local('SF-Pro-Display-Semibold'),
    url('../fonts/SF-Pro-Display-Semibold.otf') format('truetype');
}

@font-face {
  font-family: 'SF-regular';
  font-style: normal;
  font-weight: normal;
  src: local('SF-Pro-Display-Regular'),
    url('../fonts/SF-Pro-Display-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'SF-medium';
  font-style: normal;
  font-weight: normal;
  src: local('SF-Pro-Display-Medium'), url('../fonts/SF-Pro-Display-Medium.otf') format('truetype');
}

@font-face {
  font-family: 'SF-light';
  font-style: normal;
  font-weight: normal;
  src: local('SF-Pro-Display-Light'), url('../fonts/SF-Pro-Display-Light.otf') format('truetype');
}

.overlay-block {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.overlay-block-blue {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 128, 0.1);
  overflow: hidden;
}

.full-screen {
  min-width: 100vh;
  min-height: 100vh;
}

.form-group {
  position: relative;
  margin-bottom: 1.5rem;
}

.form-control {
  height: calc(1.5em + 0.75rem + 9px) !important;
}

.form-control-placeholder {
  position: absolute;
  top: 0;
  padding: 7px 0 0 13px;
  transition: all 200ms;
  opacity: 0.5 !important;
}

.hover-gray:hover {
  background-color: lightgrey !important;
}

.position-up {
  transition: box-shadow 0.3s;
  border-radius: 10px;
  background: #fff;
  float: left;
}
.position-up:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.4);
}

.form-control:focus {
  background-color: #f5f6fa !important;
  box-shadow: none !important;
  border: 2px solid #042e60 !important;
}

.form-control:focus + .form-control-placeholder,
.form-control:valid + .form-control-placeholder {
  font-size: 75%;
  transform: translate3d(0, -100%, 0);
  opacity: 1;
  color: rgb(8, 1, 1);
}

// input[type='text']:focus,
input[type='password']:focus,
input[type='number']:focus,
input[type='email']:focus {
  box-shadow: none;
  border: 2px solid #042e60 !important;
  outline: 0 none;
  color: #042e60;
}

#react-select-3-input {
  box-shadow: none !important;
}

.card-rounded {
  border-radius: 25px;
}

.text-heading {
  color: #fd6163;
}

.btn {
  background: #fd6163;
  border: #fd6163;
  border-radius: 10px;
}

.bg-dark-blue {
  background-color: #4065b4;
}

.bg-light-blue {
  background-color: #5698d4;
}

.modal-close-btn {
  position: absolute;
  right: 0;
  z-index: 1;
}

.container-top {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.tr-bg-color {
  background-color: #eaf0f4;
}

.gray-bg-color {
  background-color: #f2f2f2;
}

.solid-blue {
  background-color: #5d9de7;
}

.light-mud {
  color: #a3a6b4;
}

.gray-icon {
  color: #bcbccb;
}

a,
a label {
  cursor: pointer;
}

.pagination {
  // margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: #042E60;
  border-color: #042E60;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #042E60;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #042E60;
  border-color: transparent;
  outline: none;
  color: #fff;
}
.pagination > li > a,
.pagination > li > span {
  color: #042E60;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.forward-button {
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 28px;
  padding: 20px;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.forward-button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.forward-button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.forward-button:hover span {
  padding-right: 25px;
}

.forward-button:hover span:after {
  opacity: 1;
  right: 0;
}

.zoom {
  transition: transform 0.2s;
}

.zoom:hover {
  -ms-transform: scale(1.2); /* IE 9 */
  -webkit-transform: scale(1.2); /* Safari 3-8 */
  transform: scale(1.2);
}

.complete-button {
  border-radius: 8px;
  background-color: transparent;
  border: 2px solid #105f47;
  color: #105f47;
  text-align: center;
  font-size: 16px;
  padding: 4px;
  width: 155px;
  transition: all 0.5s;
  cursor: pointer;
}

.complete-button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.complete-button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.complete-button:hover span {
  padding-right: 25px;
}

.complete-button:hover span:after {
  opacity: 1;
  right: 0;
}

.btn-primary {
  background-color: #042e60 !important;
  color: white !important;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
  font-size: 0.75rem !important;
}

.bg-alert {
  background-color: #f88052 !important;
}

.z-2 {
  z-index: 2 !important;
}

.overlay-light-gray {
  background-color: rgba(178, 178, 178, 0.2) !important;
}

.list-circle {
  list-style-type: circle !important;
}

ul.list-line {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding-left: 10px;
  }

  li::before {
    content: '-';
    padding-right: 5px;
  }
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 10px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #042e60;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #042e60;
  cursor: pointer;
}

/**
React select search
 */
/**
 * Main wrapper
 */
.select-search {
  position: relative;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
  position: relative;
  //z-index: 1;
}

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
 * Input
 */
.select-search__input {
  display: block;
  height: 38px;
  width: 100%;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  text-align: left;
  text-overflow: ellipsis;
  padding: 10px 8px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #d8dbe0;
  color: #768192;
  background-color: #fff;
  border-radius: 0.25rem;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
 * Options wrapper
 */
.select-search__select {
  background: #fff;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

/**
 * Options
 */
.select-search__options {
  list-style: none;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  border: none;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  text-align: left;
  cursor: pointer;
  color: #768192;
  background-color: #fff;
  font-weight: 300;
  font-size: 16px;
}

.select-search__option {
  font-size: 12px;
}


.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  color: #19191a;
  background: #bebfc2;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(190, 191, 194, 0.9);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #acacaf;
  color: #fff;
}

/**
 * Group
 */
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

//.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
//  transform: rotate(45deg);
//  border-right: 1px solid #000;
//  border-bottom: 1px solid #000;
//  pointer-events: none;
//}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border: 2px solid #042e60;
}

.max-results-height-250 .select-search:not(.select-search--multiple) .select-search__select {
  max-height: 250px;

}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

.dashed-border {
  border: 1px dashed #888;
  border-radius: 10px;
}

